[role=banner]{
    position:relative;
    #logo{
        width:130px;
        padding:$baseGutter 0;
        text-align:center;
        background-color:$green;
        position:absolute;
        top:0;
        left:0;
        z-index:99999;

        svg{fill:$white;}

        @include respond-to($tabletL){
            left:1rem;
        }

        @include respond-to($mobile){
          padding: .45rem .5rem;
          width: auto;
        }
    }

}

#mobile-menu{
		position: relative;
		margin: 0;
		padding: 1rem;
    width:55px;
    text-indent: -99999px;
		border: none;
		background: none;
		float: right;
		display: none;

		@include respond-to($mobile){
			display: block;
		}

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 15px;
    width: 25px;
    height: 2px;
    background: #FFF;
    box-shadow: 0 0.35em 0 0 #FFF, 0 0.75em 0 0 #FFF;
  }

}

[role=navigation]{
    width:100%;
    position:relative;
    z-index:9999;
    top:3rem;
    background-color: rgba(0,0,0,.75);

    @include respond-to($mobile){
      top: 0;
      position: absolute;
      overflow: hidden;
    }

    > ul{
        @extend .container;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        padding-left: 130px; //space for behind logo

         > li{
            flex: 1 1 0;
            position:relative;
            > a{
                display:block;
                color:$white;
                text-transform: uppercase;
                text-decoration: none;
                padding: $baseGutter;
                text-align:center;
                font-size:1.25rem;
                font-family:$headingFontTwo;
                position:relative;
                &:after{
                    content:"|";
                    position:absolute;
                    right:0;
                    font-family:monospace;
                    color:$white !important;
                }

                &:hover{
                    color:$green;
                }
            }
            &:last-of-type{
                a:after{display:none;}
            }

            ul{
                position:absolute;
                top:100%;
                left:0;
                width:12rem;
                background-color: rgba(0,0,0,.75);
                visibility:hidden;

                li{
                    display:block;
                    a{
                        padding:.5rem $baseGutter*1.5;
                        display:block;
                        color:$white;
                        text-decoration: none;
                        &:hover{
                            color:$green;
                        }
                    }
                    &:last-of-type{
                        a{
                            padding: .5rem $baseGutter*1.5 1rem $baseGutter*1.5;
                        }
                    }
                }
            }

            &:hover{
                ul{
                    visibility:visible;
                }
            }
        }

        @include respond-to($mobile){
            flex-flow: column nowrap;
            padding-left: 0;
            display:none;
            clear:right;

            > li{
              > a{
                text-align: right;
                padding: $baseGutter $baseGutter*2 $baseGutter 0;
                &:after{display:none;}
              }
            }
        }
    }
}

[role=complementary]{
    width:$asideWidth;
    float:left;
    padding-top:10rem;

    figure{
        position:relative;
        width:262px;

        img{
            position:relative;
            z-index:1;
        }

        figcaption{
            position:absolute;
            z-index:9;
            bottom:1.5rem;
            margin-right:1.5rem;
            color:$white;
            background-color:$green;
            padding:.7rem;
            font-size:.9rem;
            font-family:'HelveticaNeueETW01-65Md';
        }
    }

    @include respond-to($tabletL){
        width:30%;
        figure{width:90%;}
    }

    @include respond-to($mobile){
      float:none;
      width:100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;
      figure{width:290px; margin:0 auto;}
    }
}


body#home{
    position:relative;
    height:100vh;

    [role=contentinfo]{
        width: 100%;
        color: $white;
        text-align:center;
        padding: $baseGutter*2 0;
        position:absolute;
        z-index:999;
        bottom:0;
        background-image:linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,.3) 10%,rgba(0,0,0,1) 70%);

        @include respond-to(600px){
          bottom:inherit;
          top:0;
          padding: 10rem 0 4rem 0;

        }

        h2{
            color:$white;
            text-transform:uppercase;
            font-size:3.5rem;
            padding-bottom:$baseGutter*2;

            @include respond-to($mobile){
              line-height: 3rem;
            }
        }

        .icons{
            padding:0 2rem;
            a{
                text-align:center;
                display:block;
                color:$green;
                font-family:$headingFont;
                text-transform:uppercase;
                letter-spacing:1px;
                padding:1rem 0;
                svg{
                    fill:$white;
                    display:block;
                    margin:0 auto .25rem auto;
                }
                svg#news{
                    margin:5px auto !important;
                }
                &:hover{
                    background-color:rgba(0,0,0,.5);
                    svg{fill:$green;}
                }
            }
        }

        p{
            padding: $baseGutter;
            font-size: .8rem;
        }

        a{
            color:$white;
            text-decoration:none;
            &:hover{color:$green;}
        }

        small{
            padding: $baseGutter;
            font-size: .7rem;
        }

    }
}

article [role=contentinfo]{
    small{
        font-size:.7rem;
        a{color:$black;text-decoration:none;&:hover{color:$green;}}
    }
}
