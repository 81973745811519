//Brash Grid
@mixin grid1{
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: $baseGutter;
}

@mixin grid2{
    flex: 0 0 calc(50.5% - #{$baseGutter});
    margin-bottom: $baseGutter;
}

@mixin grid3{
    flex: 0 0 calc(33.8% - #{$baseGutter});
    margin-bottom: $baseGutter;
}

@mixin grid4{
    flex: 0 0 calc(25.25% - #{$baseGutter});
    margin-bottom: $baseGutter;
}

@mixin grid5{
    flex: 0 0 calc(20.25% - #{$baseGutter});
    margin-bottom: $baseGutter;
}

@mixin gridReset{
    @include respond-to($mobile){
        flex-basis: 100%;
        max-width: 100%;
    }
}

@mixin gridHalf{
    @include respond-to($mobile){
        flex: 0 0 calc(50.5% - #{$baseGutter});
    }
    @include respond-to($oldmobile){
        flex-basis: 100%;
        max-width: 100%;
    }
}

@mixin smallReset{
    @include respond-to($oldmobile){
        flex-basis: 100%;
        max-width: 100%;
    }
}

.grid{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: $baseGutter 0 0 0;

    .grid-1{
        @include grid1;
    }

    .grid-2{
        @include grid2;
    }

    .grid-3{
        @include grid3;
    }

    .grid-4{
        @include grid4;
    }

    .grid-5:not(.hp-grid){
        @include grid5;
    }

    //reset all the grid classes b/c .grid is a layout utility not a specific element like a card or tile
    .grid-2,.grid-3,.grid-4,.grid-5{
        @include gridReset;
    }

}

.cards, .tiles{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: $baseGutter 0 0 0;
    overflow:hidden;

    .card-1, .tile-1{
        @include grid1;
    }

    .card-2, .tile-2{
        @include grid2;
    }

    .card-3, .tile-3{
        @include grid3;
    }

    .card-4, .tile-4{
        @include grid4;
        @include gridHalf;
    }

    .tile-5{
        @include grid5;
        @include gridHalf;
    }

    .card-2, .tile-2,.card-3{@include gridReset;}
    .tile-3{@include smallReset;}

    .card-1,.card-2,.card-3,.card-4{
        background-color: lighten($light-gray,2%);
        border-radius: $radius;
        //make them equal heights and card caption is at bottom
        display:flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        overflow:hidden;

        &:not(.fill-card) img{
            padding: $baseGutter;
            display:block;
            margin:0 auto;
        }

        figcaption{
            padding: $baseGutter;
            background-color: lighten($primary,5%);
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;

            strong{
                display:block;
                font-size:1.25rem;
                line-height:1.5rem;
            }
        }

    }

    .tile-1,.tile-2,.tile-3,.tile-4,.tile-5{
        background-color: lighten($light-gray,2%);
        position: relative;

        figcaption{
            position: absolute;
            z-index: 99;
            bottom: 0;
            padding: $baseGutter/2;
        }
        &:not(.fill-tile) figcaption{
            padding: $baseGutter*1.25;
        }

        img{
            position: relative;
            z-index: 9;
        }
        &:not(.fill-tile) img{
            padding: $baseGutter;
            display:block;
            margin:0 auto;
        }

    }

}

.thumbs{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: $baseGutter 0 0 0;

    .thumb{
        @include grid1;
    }

    .thumb-2{
        @include grid2;
    }

    .thumb-3{
        @include grid3;
    }

    .thumb,.thumb-2,.thumb-3{
        background-color: lighten($light-gray,2%);
        border-radius: $radius;
        //make them equal heights and card caption is at bottom
        display:flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        img{
            min-width:100px;
        }

        figcaption{
            padding: $baseGutter;
            strong{
                display:block;
                font-size:1.15rem;
                line-height:1.25rem;
            }
            font-size: .95rem;
        }

        @include gridReset;

    }
}

.columns{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: $baseGutter 0 0 0;

    .col,
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      flex: 0 0 auto;
      padding: $baseGutter;

      @include respond-to($mobile){
          flex-basis: 100% !important;
          max-width: 100% !important;
      }
    }

    .col {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    .col-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }

    .col-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }

    .col-3 {
      flex-basis: 25%;
      max-width: 25%;
    }

    .col-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }

    .col-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }

    .col-6 {
      flex-basis: 50%;
      max-width: 50%;
    }

    .col-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }

    .col-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }

    .col-9 {
      flex-basis: 75%;
      max-width: 75%;
    }

    .col-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }

    .col-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }

    .col-12 {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.tour{
        .active{
            background-color: $primary !important;
            &:before{border-left-color: $primary !important;}
        }
        div[class^="col-"]:first-of-type{
            z-index:9;
            padding: $baseGutter/2 $baseGutter;
        }
        div[class^="col-"]:nth-child(2){
            z-index:8;
        }
        div[class^="col-"]:nth-child(3){
            z-index:7;
        }
        div[class^="col-"]:last-of-type{
            z-index:1;
            &:before,&:after{display:none;}
        }
        div[class^="col-"]{
            position:relative;
            background-color: $dark-gray;
            color:$white;
            height: $baseGutter*3;
            padding: $baseGutter/2 $baseGutter*2;
            font-family: $headingFont;
            font-size:$baseGutter*1.5;
            line-height: $baseGutter*2;
            z-index:2;
        }
        div[class^="col-"]:before{
            z-index:99;
        	left: 100%;
        	top: 50%;
        	border: solid transparent;
        	content: " ";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        	border-color: rgba(0, 0, 0, 0);
        	border-left-color: $dark-gray;
        	border-width: $baseGutter*1.5;
        	margin-top: -$baseGutter*1.5;

            @include respond-to($mobile){
                display:none;
            }
        }
        div[class^="col-"]:after{
            z-index:96;
        	left: 100.1%;
        	top: 50%;
        	border: solid transparent;
        	content: " ";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        	border-color: rgba(0, 0, 0, 0);
        	border-left-color: $white;
        	border-width: $baseGutter*1.55;
        	margin-top: -$baseGutter*1.55;

            @include respond-to($mobile){
                display:none;
            }
        }
    }
}

.pricing{
    > div{
       border:1px solid $light-gray;
       display:flex;
       flex-flow:column wrap;
       justify-content:space-between;
       text-align:center;

       h2{
           text-transform: uppercase;
           padding: $baseGutter 0;
       }

       h3{
           text-transform: uppercase;
           padding: $baseGutter 0;
           background-color: lighten($light-gray,2%);
       }

       .price{
           color: $primary;
           font-size: $baseGutter*2;
           margin: $baseGutter/2 0;
       }

       p{margin: $baseGutter 0;}

       ul{
            margin: $baseGutter/2 0;
           li{
               text-align:left;
               min-height:$baseGutter*4;
               border-bottom:1px dotted $light-gray;
               padding: $baseGutter/2 0;
               strong{display:block;}

               .na,.inc{
                   font-style:italic;
                   display:block;
                   text-align:center;
                   color: $dark-gray;
                   line-height:$baseGutter*2.5;
               }
           }
       }

       &.highlight{
           border: 1px solid $primary;
           background-color: lighten($light-gray,2.5%);
           h3{
               background-color: $light-gray;
           }
       }
   }
}

//Eliminate Nesting Double Padding on Components
.grid .thumbs,
.grid .cards,
.grid .tiles,
.columns .thumbs,
.columns .cards,
.columns .tiles
{padding:0;}
