
body{
    font-family: $bodyFont;
    font-size: 1rem;
    line-height:1.5rem;
    background-color: $white;
    color: $black;
}

a{
    color: $primary;
    &:hover{color: darken($primary,10%);}
}

hr{
    border:none;
    border-bottom: 1px solid $gray;
    width:100%;
    height:0;
    margin: $baseGutter*2 0;
    overflow:hidden;
}

main{
    min-height: $articleMinHeight;
    position: relative;
    top: 3rem;
    background: #FFF url('main-pseudo-bg.jpg') top center repeat-y;

    @include respond-to(1170px){
        background: #FFF url('main-pseudo-bg-1024.jpg') top center repeat-y;
    }

    @include respond-to($tabletL){
        background: $gray;
    }
}

article{
    @include headings {padding:0 0 $baseGutter 0;}
    width:100%;
    padding-left:$asideWidth;
    min-height: $articleMinHeight;

    .gutter{
        padding:3rem 0 3rem 3rem;
    }

    h1{
        color: $green;
        text-transform:uppercase;
        padding-bottom:1rem;
        margin-bottom:1rem;
        border-bottom:1px solid #333;
        letter-spacing:1px;
        span{
            vertical-align:bottom;
        }

        svg{
            fill:$green;
            margin-right:.5rem;
        }

    }

    .post-tn{
        float:left;
        margin:0 $baseGutter $baseGutter 0;
    }

    p{
        margin-bottom: $baseGutter;
    }

    ul{
        margin:0 $baseGutter*2 $baseGutter $baseGutter*2;
        list-style: disc;
        line-height: $baseGutter*1.75;
        ul{
            margin:0 $baseGutter*2 0 $baseGutter*2;
        }
    }

    .bio-meta{
        padding:$baseGutter/2 0 $baseGutter*1.5 0;
        span{
            font-family:$headingFont;
            letter-spacing:1px;
            font-size:1.85rem;
            color:$green;
            display:inline-block;
            padding-right:.5rem;
        }
        a{
            color:$green;
            text-decoration:none;
            border-left:1px solid $green;
            display:inline-block;
            padding:0 .5rem;
            line-height:1rem;
        }
    }

    .project-meta{
        h2{
            display:inline-block;
            font-family: $boldFont;
            padding-right:.5rem;
            font-size:1.25rem;
        }
        span{
            border-left:1px solid $black;
            display:inline-block;
            padding:0 .5rem;
            line-height:1rem;
        }
    }

    @include respond-to($tabletL){
        background: $white;
        width:70%;
        float:right;
        padding-left:0;
        .gutter{padding:2rem;}
    }

    @include respond-to($mobile){
      float:none;
      width:100%;
    }  
}

.p-grid-title{
    font-family: $headingFont;
    font-size:1.75rem;
}

.news{

    #heading{
        font-family: $boldFont;
        font-size:1.25rem;
    }
}

.news-grid-row{
    padding-bottom:$baseGutter;
    margin-bottom:$baseGutter;
    border-bottom:1px solid $black;
}
