//Brash Utilities
.block{display:block;}

[aria-hidden="true"]{display:none;}
[aria-hidden="false"]{display:block;}

.clearfix {
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

@mixin headings {
    h1, h2, h3, h4, h5, h6 {
        @content;
    }
}

.m1{margin: $baseGutter !important;}
.m2{margin: $baseGutter*2 !important;}
.mt1{margin-top: $baseGutter !important;}
.mt2{margin-top: $baseGutter*2 !important;}
.mb1{margin-bottom: $baseGutter !important;}
.mb2{margin-bottom: $baseGutter*2 !important;}

.p1{padding: $baseGutter !important;}
.p2{padding: $baseGutter*2 !important;}
.pt1{padding-top: $baseGutter !important;}
.pt2{padding-top: $baseGutter*2 !important;}
.pb1{padding-bottom: $baseGutter !important;}
.pb2{padding-bottom: $baseGutter*2 !important;}

.py1{padding: $baseGutter 0 !important;}
.px1{padding: 0 $baseGutter !important;}

.center{text-align:center;}
.flex-center{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-flow: column nowrap;
}

.mobile-show{display:none;}

@include respond-to($mobile){
  .mobile-show{display:block;}
  .mobile-hide{display:none;}
}
