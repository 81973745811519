//Brash Vars

$radius: .5rem;

//Type
/*
font-family:'HelveticaNeueETW01-65Md';
font-family:'HelveticaNeueETW01-45Lt';
font-family:'Futura PT W01 Medium';
font-family:'FuturaPTW01-CondMedium';
*/
$bodyFont: 'HelveticaNeueETW01-45Lt', Helvetica Neue, Helvetica, sans-serif;
$boldFont: 'HelveticaNeueETW01-65Md', Helvetica Neue, Helvetica, sans-serif;
$headingFont: 'FuturaPTW01-CondMedium', 'Futura', Arial, sans-serif;
$headingFontTwo: 'Futura PT W01 Medium', 'Futura', Arial, sans-serif;

//Colors
$white: #FFFFFF;
$black: #000000;
$red: #be4118;
$green: #6B8B40;
$gray: #F1F1F1;
$dark-gray: lighten($black,15%);
$light-gray: lighten($gray,5%);
$pink: #FF337A;
$blue: #00B7D1;
$nude: #DEDCD3;

$primary: $green;
$secondary: $pink;

//Spacing
$baseGutter: 1rem;
$asideWidth: 300px;
$articleMinHeight: 600px;

//transition timing
$timing: .4s;

//Debug
//.debug{border: 1px solid $secondary;}
