@keyframes pulse {
from {transform: scale3d(.1, .1, .1);}
50% {transform: scale3d(1.02, 1.02, 1.02);}
to {transform: scale3d(1, 1, 1);}
}

.pulse{
    animation-name: pulse;
    animation-duration: $timing;
}

@keyframes fadein {
from {opacity: .1;}
to   {opacity: 1;}
}

.fadein{
    animation-name: fadein;
    animation-duration: $timing;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  from {opacity: 0; transform: translate3d(3000px, 0, 0);}
  60% {opacity: 1;transform: translate3d(-25px, 0, 0);}
  75% {transform: translate3d(10px, 0, 0);}
  90% {transform: translate3d(-5px, 0, 0);}
  to {transform: none;}
}

.bounceInRight {
  animation-name: bounceInRight;
  animation-duration: $timing*2;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;transform: scale3d(.3, .3, .3);}
  20% {transform: scale3d(1.1, 1.1, 1.1);}
  40% {transform: scale3d(.9, .9, .9);}
  60% {opacity: 1;transform: scale3d(1.03, 1.03, 1.03);}
  80% {transform: scale3d(.97, .97, .97);}
  to {opacity: 1;transform: scale3d(1, 1, 1);}
}

@keyframes bounceOut {
  20% {}
  50%, 55% {opacity: 1;transform: scale3d(1.1, 1.1, 1.1);}
  to {opacity: 0;transform: scale3d(.3, .3, .3);}
}

.bounceOut {animation-name: bounceOut; animation-duration: $timing*2;}
.bounceIn {animation-name: bounceIn; animation-duration: $timing*2;}  
