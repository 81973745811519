
.brash-modal{
    display:none;
    width:80%;
    min-height:500px;
    background-color: $white;
    position:absolute;
    top:60px;
    left:9.5%;
    z-index:99999;
    box-shadow: 0 0 6rem $black;

    &.bounceIn{opacity:1;}

    .brash-modal_close{
        text-decoration:none;
        position:absolute;
        top:0;
        right:5px;
        padding:$baseGutter/2;
        color:$black;
        font-size:1.2rem;
        display:inline-block;
        &:before{content:"X";}
    }
}
